@import url('https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css');
@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,700,500,600');
/* The following line is used to measure usage of this code in production. For more info see our usage billing page */
@import url('https://px.animaapp.com/6402daa56a37db7167320f64.6402daa7c9953e08464984a6.rI804gi.rcp.png');

.screen a {
  display: contents;
  text-decoration: none;
}

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal>* {
  flex-shrink: 0;
  pointer-events: auto;
}

* {
  box-sizing: border-box;
}

.route-container {
  width: 100%;
 
  overflow-y: scroll;
  position: sticky;
  left: 80px;
}

.route-container::-webkit-scrollbar {
  width: 0;
  display: none;
}

.app-container {
  display: flex;
  height: 100vh;
}