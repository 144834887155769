.marking-criteria-parent-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: IBM Plex Sans;
}

.marking-criteria-title {
  color: #301b72;
  font-size: 20px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  gap: 20px;
}

.marking-criteria-data-parent {
  display: flex;
  gap: 18px;
}

.marking-criteria-column-width {
  flex: 1;
  flex-basis: 0;
  padding: 8px 12px;
}

.marking-criteria-column-width-selected {
  flex: 1;
  flex-basis: 0;
  background-color: #f1e7ff;
  border-radius: 12px;
  padding: 8px 12px;
}

.marking-criteria-content-card {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(114, 0, 224, 0.1);
  padding: 8px 12px;
}

.marking-criteria-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.marking-criteria-heading {
  color: #1e252a;
  font-size: 18px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.marking-criteria-content {
  color: var(--chicago);
  font-size: 16px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}