.dialog-parent-container {
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  border-radius: 12px;
  box-shadow: 0px 4px 16px 0px rgba(114, 0, 224, 0.1);
  /* background-color: red; */

}

.content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  flex-direction: column;
  position: absolute;
}

.dialog-heading {
  color: var(--text, #1e252a);
  text-align: center;
  font-family: IBM Plex Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.methodology-options {
  display: flex;
  align-items: flex-start;
  gap: 30px;
}

.rubrics,
.strength-target {
  display: flex;
  padding: 30px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 12px;
  border: 1px solid #e9e9e9;
  background: #fff;
  color: var(--text, #1e252a);
  text-align: center;
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.rubrics:hover,
.strength-target:hover {
  border-color: var(--light-mode-purple);
}


/* --light-mode-purple */