/* .ql-editor {
  border: 1px solid black;
  border-color: black;
  border-radius: 0px 0px 8px 8px;
} */
.ql-container:not(.ql-blur) .ql-cursor {
  display: none;
}

.ql-container .ql-cursor {
  opacity: 1 !important;
  background-color: rgba(
    0,
    0,
    255,
    0.2
  ); /* Change the color and opacity as desired */
}
.ql-editor p span{
  color: var(--text);
}
.ql-editor strong {
  font-weight: bold;
}
.ql-editor em {
  font-style: italic;
}
.ql-editor.ql-blank::before {
  color: black;
}
.quill-highlight {
  /* background-color: #fff9c4; */
}




.ql-toolbar{
  padding: 10px 0px;
  border: none !important;
  border-bottom: 1px solid rgba(201, 198, 204, 0.5) !important;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  z-index: 1;
  margin-bottom: 10px;
  background-color: white;
}

.ql-toolbar.ql-snow{
  border-bottom: none;
}
.ql-container {
  overflow: hidden;
  border: none !important;
}


.quill-editor-container {
  background-color: var(--white);
}


.ql-editor{
  border: none;
  padding: 40px 80px !important;
}

.ql-editor::before {
  padding: 0px 65px !important;
  font-family: var(--font-family-ibm_plex_sans) !important;

}

.ql-formats {
  display: flex;
  gap: 20px;
}

.ql-formats button,
.ql-formats .ql-picker {
  position: relative;
}

.ql-formats button[class^='ql-'] {
  position: relative;
}

.ql-formats button[class^='ql-']::after {
  white-space: nowrap;
  position: absolute;
  display: none;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -2px);
  background-color: #000;
  font-size: 10px;
  color: #fff;
  z-index: 10;
  border-radius: 4px;
  padding: 2px 5px;
}

.ql-formats button[class^='ql-']:hover::after {
  display: block;
}

.ql-formats .ql-image::after{
  content: 'Image';
}

.ql-formats .ql-bold::after {
  content: 'Bold';
}

.ql-formats .ql-italic::after {
  content: 'Italic';
}

.ql-formats .ql-underline::after {
  content: 'Underline';
}

.ql-formats .ql-list[value='bullet']::after {
  content: 'Bullet List';
}

.ql-formats .ql-list[value='ordered']::after {
  content: 'Numbered List';
}

.ql-formats .ql-link::after {
  content: 'Link';
}

.ql-formats .ql-clean::after {
  content: 'Clean Markup';
}


.ql-formats button:hover{
 background-color: #7200e0 !important;
 border-radius: 10px;
}

.ql-formats button:hover .ql-stroke{
fill: none;
stroke: white !important;
}

.ql-picker-label:hover .ql-stroke {
  fill: #7200e0 !important;
  stroke: #7200e0 !important;
}

.ql-picker-label:hover{
  color: #7200e0 !important;
}

.ql-picker-item.ql-selected{
  color: #7200e0 !important;
}

.ql-picker-item:hover{
  color: #7200e0 !important;
}

.FloatingEditorDialogueBox{ 
  position: absolute; 
  left: 90%;
  display: flex;
  flex-direction: column;
  padding: 10px 0 20px;
  background: white;
  border-radius: 10px;
  max-width: 90%;
  min-height: 150px;
  max-height: 350px;
  z-index: 10000;
  border: 1px solid rgba(201, 198, 204, 0.5);
  box-shadow: inset 0 0 4px rgba(112, 112, 112, 0.25);
}

.modalHeading{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px 6px;
  border-bottom: 1px solid rgba(201, 198, 204, 0.5);
}

.modalHeading h1{
  font-family: var(--font-family-ibm_plex_sans);
  color: rgba(86, 81, 91, 1);
  font-weight: 600;
  font-size: var(--font-size-l);
  line-height: 17px;
}

.closeButton{
  border: none;
  background: none;
  font-size: 1.3rem;
  padding: 0;
  margin: 0;
  color: rgba(86, 81, 91, 1);
  cursor: pointer;
}




