.rubrics-table {
  border-radius: 15px !important;
  padding: 10px;
  box-shadow: rgba(10, 10, 11, 0.2) 0px 5px 20px 0px;
}

.st-table {
  border: 1px solid #eeecec;
  border-radius: 15px;
  box-shadow: rgba(10, 10, 11, 0.2) 0px 5px 20px 0px;
  padding-bottom: 10px;
}

.parent-container {
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 16px;
}

.parent-container-sm {
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 0px;
}

.title {
  color: #301b72 !important;
  font-size: 20px !important;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  display: flex;
  margin: 10px;
}

.mc-title {
  width: 100%;
  padding: 8px;
  text-align: center;
  color: var(--royal-purple);
  font-size: var(--font-size-xl);
  font-weight: bold;
}

.data-parent {
  display: flex;
  margin: 0 15px 10px;
}

.column-width {
  flex: 1;
  flex-basis: 0;
}

.content-card {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(114, 0, 224, 0.1);
  padding: 8px 12px;
}

.data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 8px;
}

.data:last-child {
  border-right: none;
}

.heading {
  color: #1e252a;
  font-size: 18px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px !important;
}

.mk-heading {
  color: #1e252a;
  font-size: 18px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
}

.content {
  color: var(--text, #1e252a);
  font-size: 16px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.7;
}

.option-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 20px;
  border-bottom: none;
}



.st-subHeader {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.st-title {
  color: var(--royal-purple);
  font-size: var(--font-size-xl);
  font-weight: bold;
  margin: 10px auto;
  text-align: center;
  font-family: IBM Plex Sans;
  text-align: center;
}


.st-sub-title {
  color: #1e252a;
  font-size: 18px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 8px 0 10px;
  width: 100%;
  min-width: 150px;
  text-align: center;
}

.st-sub-title-sm {
  color: #1e252a;
  font-size: 18px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 8px;
  width: 100%;
  min-width: fit-content;
  text-align: center;
}

.mk-column {
  padding: 8px;
}

.mk-empty-column {
  border: 1px solid transparent;
  padding: 8px;
}

.action_button{
  padding: 10px 0px;
}