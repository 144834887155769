.feedback-teacher-laptop {
  align-items: flex-start;
  background-color: var(--white-pointer);
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 140px;
  position: relative;
}

.focus-area {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid #f1e7ff;
}

.image {
  display: flex;
  align-items: center;
}

.text {
  color: #1e252a;
  font-size: 14px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
}
