.parent-card {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 16px;
}

.heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading-text {
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-family: IBM Plex Sans;
  font-size: 19px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #4b464f;
}

.download-container {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 8px 12px 8px 12px;
  border-radius: 32px;
  border: 1px solid #7200e0;
  gap: 4px;
}

.download-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.download-tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 4px;
  z-index: 100;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  display: none;
  font-family: 'IBM Plex Sans', Helvetica;
  font-size: 12px;
}

.delete-container:hover .download-tooltip {
  display: block;
}

.line {
  height: 1px;
  width: 100%;
  background: #f1e6fc;
}

.graph {
  width: 178px;
}

.graph-container {
  display: flex;
  justify-content: center;
  padding: 20px 40px 20px 40px;
  gap: 45px;
}

.graph-data {
  display: flex;
  flex-direction: column;
  /* padding: 20px 0px;
  margin: 0px 20px;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid rgba(114, 0, 224, 0.1);*/
  background: #fff;
  /* box-shadow: 0px 4px 16px 0px rgba(114, 0, 224, 0.1);  */
  padding: 20px 0px 20px 0px;
  border-radius: 16px;
  gap: 20px;
}

.subheading-text {
  color: #1e252a;
  font-family: IBM Plex Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.5px;
  padding-left: 20px;
}

.progress {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.legend-item {
  display: flex;
  flex: 0 1 48%;
  align-items: flex-start;
  gap: 8px;
}

.legend-color {
  margin-top: 4px;
  /* width: 12px; */
  /* height: 12px; */
  border-radius: 6px;
  width: 16px;
  height: 8px;
}

.legend-label {
  color: var(--text, #1e252a);
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-style: normal;
  display: flex;
  justify-content: flex-start;
  font-weight: 400;
  line-height: normal;
  max-width: 300px;
}

.legend-label-percentage {
  color: var(--text, #1e252a);
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.legend-label-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 4px;
}

.legend {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  gap: 20px;
  /* width: 55%; */
  padding-right: 20px;
  overflow-y: auto;
}

@media (min-width: 766px) and (max-width: 1024px) {
  .legend {
    flex: 1;
  }

  .legend-item {
    flex-basis: 100%;
  }
}

@media (max-width: 765px) {
  .graph-container {
    flex-direction: column;
    align-items: center;
  }

  .legend {
    width: 100%;
    height: auto;
  }

  .legend-item {
    flex-basis: 100%;
  }
}

.graph-heading {
  padding: 0px 20px 20px 20px;
  border-bottom: 1px solid #f2f1f3;
}
