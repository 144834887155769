.progress-bar-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-self: stretch;
}

.progress-bar-heading {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  justify-content: space-between;
}

.title {
  color: #1e252a !important;
  font-family: IBM Plex Sans;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}

.ratio-bar-heading{
  display: flex;
  justify-content: space-between;
}

.ration-title,
.ration-number{
  font-family: var(--font-family-ibm_plex_sans);
  font-weight: 400;
  font-size: var(--font-size-l);
  color: var(--text);
}

.progress-container {
  width: 100%;
  height: 4px;
  background-color: #f1e7ff;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar {
  background-color: #7200e0;
  transition: width 0.5s ease-in-out;
  height: 100%;
}
