.parent-containe {
  display: flex;
  flex-direction: column;
  gap: 30px;
  background: #fcfaff;
  
}

.child-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 1440px;
  margin: auto;
  padding: 0px 60px;
}

.breadcrumb {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading-text {
  padding-left: 0px !important;
}

.save {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  border: 1px solid var(--light-mode-purple, #7200e0);
  background: var(--light-mode-purple, #7200e0);
  color: #fff;
  text-align: center;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.title-input {
  display: flex;
  padding: 13px 20px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid #7c92a1;
  background: #fff;
  width: 100%;
}

.form-container {
  display: flex;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(114, 0, 224, 0.1);
}

.subheading {
  color: var(--text, #1e252a);
  font-family: IBM Plex Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  flex: 1 0 0;
}

.border {
  background: #f1e6fc;
  height: 1px;
  width: 100%;
}

.add-criteria {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 30px;
  border: 1.5px solid var(--light-mode-purple, #7200e0);
  color: var(--light-mode-purple, #7200e0);
  text-align: center;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.criteria-container {
  position: relative;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid rgba(114, 0, 224, 0.2);
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(114, 0, 224, 0.1);
}

.remove-and-criteria {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 0px;
}

.criteria {
  display: flex;
  padding: 3px 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 0px 0px 11.5px 11.5px;
  border: 1px solid #dec7ff;
  background: #f1e7ff;
  color: var(--royal-purple, #301b72);
  text-align: center;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.remove {
  display: flex;
  padding: 3px 8px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 0px 0px 11.5px 11.5px;
  border: 1px solid #f27979;
  background: #fed2d2;
  color: var(--delete-red, #8a1c1c);
  text-align: center;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.strength-and-target-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.strength,
.target {
  position: relative;
  display: flex;
  padding: 0px 20px 20px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px dashed rgba(30, 37, 42, 0.3);
  background: #fff;
  width: 100%;
}

.strength-text,
.target-text {
  display: flex;
  padding: 3px 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 0px 0px 11.5px 11.5px;
  border: 1px solid #dec7ff;
  background: #f1e7ff;
  color: rgba(48, 27, 114, 0.7);
  text-align: center;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.criteria-option {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.remove-option {
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
  color: var(--light-mode-purple, #7200e0);
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.remove-option:hover {
  opacity: 0.7;
}

.delete-and-save {
  display: flex;
  gap: 12px;
  align-items: center;
}

.delete {
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  color: var(--light-mode-purple);
  font-size: var(--font-size-xs);
  font-weight: 400;
}

.delete:hover {
  opacity: 0.6;
}

@media screen and (max-width: 1439px) {
  .child-container {
    width: 100%;
  }
}

@media screen and (max-width: 1023px) {
  .heading {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }

  .save {
    width: fit-content;
  }
}